import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

$(document).ready(function () {
  var charts = $(".chart");
  if (charts.length < 1) return;

  Chart.plugins.register(ChartDataLabels);
  Chart.defaults.global.legend.display = false;

  $(".chart").each(function () {
    var chartDiv = $(this);
    var barsNumber = 5;
    if (chartDiv.data("bars-number")) {
      barsNumber = chartDiv.data("bars-number");
    }
    var barThickness = 30;
    if (chartDiv.data("bar-thickness")) {
      barThickness = chartDiv.data("bar-thickness");
    }

    var labels = chartDiv
      .find("th")
      .toArray()
      .map(function (i) {
        return i.innerText;
      });
    var data = chartDiv
      .find("td")
      .toArray()
      .map(function (i) {
        return i.innerText;
      });

    chartDiv.find("table").hide();

    while (labels.length < barsNumber) {
      labels.push("");
    }

    // To get numbers inside of bars: https://stackoverflow.com/questions/31631354/how-to-display-data-values-on-chart-js
    var chart = new Chart($(this).find("canvas")[0], {
      type: "horizontalBar",
      data: {
        legend: false,
        labels: labels,
        datasets: [
          {
            barThickness: barThickness,
            label: "# of entries",
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        scales: {
          xAxes: [
            {
              display: false,
              gridLines: {
                display: false,
                color: "#FFFFFF",
              },
              ticks: {
                precision: 0,
                beginAtZero: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
                color: "#FFFFFF",
              },
            },
          ],
        },
      },
    });
  });
});
