// scss
import "../scss/bootstrap.scss";
import "../scss/app.scss";

// js
import "bootstrap";
import jQuery from "jquery";
import "jquery-sticky";
import "./edit";
import "./charts";
import "./search";

window.$ = window.jQuery = jQuery;

$(document).ready(function () {
    $(".content-header").sticky();

    removeRelationWithinEntity();
});


/**
 * The item is marked to 'delete' and can be undo before 'Save changes'
 */
function removeRelationWithinEntity() {
    $(document)
        .on('mousedown', '.field-collection-delete-button', function (e) {
            let $this = $(this);
            let formGroup = $(this).closest('.form-group');

            $this.attr('disabled', true);
            setTimeout(function (_this) {
                $(_this).removeAttr('disabled');
            }, 500, this);

            if (formGroup.hasClass('remove-group')) {
                // Un mark from remove
                $this.find('i').attr('class', 'far fa-trash-alt');
                $this.attr('title', 'Remove the item');
                formGroup.find('input, textarea, select').removeAttr('disabled');
                formGroup.removeClass('remove-group');
            } else {
                // Mark to remove
                $this.find('i').attr('class', 'fa fa-undo');
                $this.attr('title', 'Undo removal of the item');
                formGroup.find('input, textarea, select').attr('disabled', 'disabled');
                formGroup.addClass('remove-group');
            }
        });
}