import $ from "jquery";

$(document).ready(function () {
  // Open first not empty tab
  $(".nav-item.enabled a").first().trigger("click");

  // Deal with errors
  initOpenInvalid();
});

/**
 * Method: initOpenInvalid
 *
 * Error handling
 * - open tab of first error present
 * - open all the accordions where the error is present
 *
 * @return void
 */
function initOpenInvalid() {
  invalidField = $(".is-invalid");
  if (invalidField.length < 1) return;

  var firstInvalid = invalidField.first();

  // Open tab
  var tabId = firstInvalid.parents(".tab-pane").first().attr("id");
  if (tabId) {
    $('a[href="#' + tabId + '"]').trigger("click");
  }

  invalidField.each(function () {
    // Open accordion
    $(this)
      .parents(".accordion-item")
      .first()
      .find(".accordion-button")
      .trigger("click");

    // Mark tab
    var tabId = $(this).parents(".tab-pane").attr("id");
    console.log(tabId);
    if (tabId) {
      $('a[href="#' + tabId + '"]')
        .parent()
        .addClass("error");
    }
  });
}
