import axios from "axios";
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

$(document).ready(function () {
  searchDuplicate();
});

/**
 * Method: searchDuplicate
 *
 * Searches duplicate based field and asks against url
 *
 * @return void
 */
function searchDuplicate() {
  var url = $("[data-search-duplicate-url]").data("search-duplicate-url");
  if (url === undefined) {
    return;
  }

  var field = $("[data-search-duplicate-field]").data("search-duplicate-field");

  $(field).on("input", function () {
    var charCount = $(this).val().length;
    if (charCount < 3 && charCount !== 0) return;

    var form = $(this).parents("form").first();
    var formData = new FormData(form[0]);
    axios({
      method: "post",
      url: url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        var html = "";
        if (response.data.length > 0) {
          html += "<div class='inner'>";
          html += "<strong>Possible duplicates</strong>";
          html += "<ul>";
          for (const line of response.data) {
            html += "<li>" + line + "</li>";
          }
          html += "</ul>";
          html += "</div>";
        }
        $(".search-duplicate-results").html(html);
      })
      .catch(function (response) {
        //handle error
        console.log(response.data);
      });
  });
}
